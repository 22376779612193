export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [6,[2]],
		"/(app)/admin": [7,[2,3]],
		"/(app)/admin/evaluations": [8,[2,3]],
		"/(app)/admin/settings": [9,[2,3]],
		"/analytics": [30],
		"/auth": [31],
		"/connectwise-url": [32],
		"/(app)/c/[id]": [10,[2]],
		"/error": [33],
		"/(app)/playground": [11,[2,4]],
		"/(app)/playground/completions": [12,[2,4]],
		"/(app)/playground/notes": [13,[2,4]],
		"/s/[id]": [34],
		"/watch": [35],
		"/(app)/workspace": [14,[2,5]],
		"/(app)/workspace/functions": [15,[2,5]],
		"/(app)/workspace/functions/create": [16,[2,5]],
		"/(app)/workspace/functions/edit": [17,[2,5]],
		"/(app)/workspace/knowledge": [18,[2,5]],
		"/(app)/workspace/knowledge/create": [20,[2,5]],
		"/(app)/workspace/knowledge/[id]": [19,[2,5]],
		"/(app)/workspace/models": [21,[2,5]],
		"/(app)/workspace/models/create": [22,[2,5]],
		"/(app)/workspace/models/edit": [23,[2,5]],
		"/(app)/workspace/prompts": [24,[2,5]],
		"/(app)/workspace/prompts/create": [25,[2,5]],
		"/(app)/workspace/prompts/edit": [26,[2,5]],
		"/(app)/workspace/tools": [27,[2,5]],
		"/(app)/workspace/tools/create": [28,[2,5]],
		"/(app)/workspace/tools/edit": [29,[2,5]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';